$sa2-dark: #181C24;
$sa2-midnight: #13161D;
$sa2-grey: #242932;
$sa2-light-grey: #5d5d5d;
$sa2-white: #F8F8FF;
$sa2-purple: #4C46DC;
$sa2-lilac: #818AD8;
$sa2-green: #7DFFB4;
$sa2-dark-green: #334b3c;


// todo separate all this into separate files or styled components

/* Fonts */

h1 {
  font-family: 'RaporExtraBold', sans-serif !important;
  font-size: 64px !important;
  line-height: 64px !important;
}

h2 {
  font-family: 'Barlow-Bold', sans-serif !important;
  font-size: 24px !important;
  line-height: 42px !important;
}

h3 {
  font-family: 'Barlow-Regular', sans-serif !important;
  font-size: 18px !important;
  line-height: 42px !important;
}

p {
  font-family: 'Barlow-Medium', sans-serif !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

button {
  font-family: 'Barlow-SemiBold', sans-serif !important;
  font-size: 18px !important;
  line-height: 22px !important;
}

.tab {
  font-family: 'Barlow-Regular', sans-serif !important;
  font-size: 30px !important;
  line-height: 36px !important;
}

.tab-selected {
  font-family: 'Barlow-Bold', sans-serif !important;
  font-size: 30px !important;
  line-height: 36px !important;
}


/* App */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-small {
  width: 40px !important;
  height: 40px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Gallery Page */
.gallery-page {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //font-size: calc(10px + 2vmin);

  //background-blend-mode: multiply;

  width: 100vw;
  height: 100vh;

  &__container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 20px 20px;

    height: 100vh;
  }

  $margin-top-amount: 44px;

  &__box-1 {
    box-sizing: border-box;
    margin-top: $margin-top-amount;
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
  }
  &__box-2 {
    box-sizing: border-box;
    margin-top: $margin-top-amount;

    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
  }
  &__box-3 {
    box-sizing: border-box;
    margin-top: $margin-top-amount;

    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
  }
  &__bottom-row {
    box-sizing: border-box;
    grid-area: 2 / 1 / 3 / 4;
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    //max-height: 10vh;
    align-items: center;
    justify-content: center;
  }

  &__action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__background {
    top:0;
    left: 0;

    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 100vh;

    background-size: 10000px 10000px !important;
    filter: blur(2px);
  }

  &__select-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__select-buttons-left {
    display: flex;
    flex-direction: row;
  }

}

.blender {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}

/* Navigation */
.navbar {
  z-index: 99;
  display: flex;
  position: fixed;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  top: 0;
  left: 0;

  &__right-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

/** Galleries Page **/
.galleries-page {
  //padding-top: 60px !important;
  &__container {
    //padding: 30px;
  }

  &__title-holder {
    padding: 1px 20px;
    background-color: white;
    //color: white;
  }

  &__section {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    padding: 60px;
    word-break: break-all;

    &-box-1 {
      flex: 1 1 30%; /*grow | shrink | basis */
    }
    &-box-2 {
      flex: 1 1 30%; /*grow | shrink | basis */

    }
    &-box-3 {
      flex: 1 1 30%; /*grow | shrink | basis */
    }
  }

  &__gallery-image {
    border-radius: 15px;
    width: 300px;
    height: 300px;
  }

  &__section-box-3 {
    column-count: 5;
    column-gap: 1em;
    width: 100%;
    //max-height: 100vh;
    overflow: auto;

    img {
      width: 100%;
      object-fit: cover;
      display: block;
      margin-bottom: 1em;
      break-inside: avoid;
    }
  }
}

/* General Styles */
.bold {
  font-weight: bold;
}

.tab, .site-icon, button {
  cursor: pointer;
}

/* show-manual-upload-modal */
.show-manual-upload-modal {
  display: flex;
  flex-direction: column;
}



/** Loading spinner **/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #cef;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #cef transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.hidden {
  display: none;
}



/* Login Page */
.dashboard {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //font-size: calc(10px + 2vmin);

  //background-blend-mode: multiply;

  width: 100%;
  height: 100%;

  flex-direction: row;

  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;

  &__area {
    margin-top: 60px;
    min-width: 60vw;
    display: flex;
    flex-direction: column;
  }

  &__background {
    top: 0;
    left: 0;

    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 100vh;

    background-size: 10000px 10000px !important;
    filter: blur(2px);
  }

  &__admin-text {
    color: white;
    font-size: 100px !important;
  }
}

.navbar-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}


/* Login Page */
.login-screen {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //font-size: calc(10px + 2vmin);

  //background-blend-mode: multiply;

  overflow-x: hidden;

  padding-top: 100px;
  padding-bottom: 100px;

  background: rgb(47,34,70);
  background: radial-gradient(circle, rgba(47,34,70,1) 0%, rgb(19, 16, 38) 100%);
  width: 100vw;
  min-height: 100vh;

  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  position: relative;


  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__sign-up {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: white;
    padding: 30px;
    border-radius: 30px;
  }

  &__sign-up-label {
    color: $sa2-grey;
    margin-top: 10px;
    font-size: 16px !important;
  }

  &__title {
    font-size: 30px !important;
  }

  &__benefits {
    margin-left: 30px;
    font-size: 26px !important;

    margin-bottom: 50px;
  }

  &__left-column {
    z-index: 1;
    padding: 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  &__right-column {
    z-index: 1;
    padding: 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  &__grid {
    position: absolute;
    display: flex;
    align-self: center;
    justify-self: center;
    z-index: 0;
  }

  &__laptop {
    width: 100%;
  }

  &__sign-up-title {
    font-size: 30px !important;
    color: $sa2-grey !important;
  }

  &__background {
    top: 0;
    left: 0;

    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 100vh;

    background-size: 10000px 10000px !important;
    filter: blur(2px);
  }

  &__admin-text {
    color: white;
    font-size: 100px !important;
  }
}
